import { render, staticRenderFns } from "./CarAdd.vue?vue&type=template&id=284ac396&scoped=true&"
import script from "./CarAdd.vue?vue&type=script&lang=js&"
export * from "./CarAdd.vue?vue&type=script&lang=js&"
import style0 from "./CarAdd.vue?vue&type=style&index=0&id=284ac396&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "284ac396",
  null
  
)

export default component.exports