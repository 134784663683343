<template>
    <div class="menu-page">
        <h1>Carros</h1>

        <div class="find-bar mb-4">
            <v-text-field autocomplete="nope" dense v-model="filter" label="Busca por modelo do carro" outlined hide-details="auto"></v-text-field>
            <v-btn class="ml-2 default-button" color="#123cd7" @click.native="getCars({ filter: { model: filter } })">Buscar</v-btn>
        </div>

        <div class="new-car-button mb-4">
            <v-btn class="default-button" color="#123cd7" @click.native="limpaFormulario(); modalNewCar = true">Novo carro</v-btn>
        </div>

        <div>
            <v-data-table :headers="headers" :items="cars" :items-per-page="50" class="elevation-1" :footer-props="{
                'items-per-page-text': 'Linhas por página',
                'items-per-page-all-text': 'Tudo',
                'items-per-page-options': [5, 10, 30, 50, 100, -1],
            }">

                <template v-slot:[`item.pictures`]="{ item }">
                    <div class="pictures-container">
                        <v-img class="image-thumb" contain :src="pic.thumb" v-for="(pic, index) in item.pictures.slice(0, 1)" :key="index"></v-img>
                    </div>
                </template>

                <template v-slot:[`item.price`]="{ item }">
                    R$ {{ item.price.toLocaleString('pt') }}
                </template>

                <template v-slot:[`item.km`]="{ item }">
                    {{ item.price.toLocaleString('pt') }}
                </template>

                <template v-slot:[`item.store`]="{ item }">
                    {{ item.store.name }}
                </template>

                <template v-slot:[`item.model`]="{ item }">
                    <v-icon color="success" small v-if="item.fipe_brand_id && item.fipe_model_id && item.fipe_year_id">mdi-check-circle</v-icon>
                    {{ item.model }}
                </template>

                <template v-slot:[`item.created`]="{ item }">
                    {{ new Date(item.created).toLocaleString('pt-BR').replace(',', ' ') }}
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <div class="actions-container">
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon medium class="mr-4" color="#123cd7" @click.stop="new_car = item; modalNewCar = true" v-bind="attrs" v-on="on"> mdi-store-edit-outline</v-icon>
                            </template>
                            <span>Editar carro</span>
                        </v-tooltip>

                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon medium class="mr-4" color="error" @click.stop="new_car = item; dialog = true" v-bind="attrs" v-on="on"> mdi-store-off-outline</v-icon>
                            </template>
                            <span>Apagar carro</span>
                        </v-tooltip>
                    </div>
                </template>
            </v-data-table>
        </div>

        <v-bottom-sheet scrollable v-model="modalNewCar">
            <v-card class="bottom-modal-container" height="auto">
                <v-card-text>
                    <v-row class="pt-2">
                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-autocomplete autocomplete="nope" :no-data-text="noDataText" dense :items="$root.$refs.global.car_brands" v-model="new_car.brand" item-text="brand" item-value="brand" label="Marca" outlined hide-details="auto" return-object @change="changeBrand"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-autocomplete autocomplete="nope" :no-data-text="noDataText" dense :items="brand_models" v-model="new_car.model" item-text="model" item-value="model" label="Modelo" outlined hide-details="auto" return-object @change="changeModel"></v-autocomplete>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-autocomplete autocomplete="nope" :no-data-text="noDataText" dense :items="model_types_by_year.years" label="Ano Modelo" outlined hide-details="auto" return-object @change="changeYearModel">
                                <template slot="selection" slot-scope="data">
                                    <v-chip class="ma-1" small>
                                        {{ data.item.model_year }} | {{ data.item.fuel }}
                                    </v-chip>

                                </template>
                                <template slot="item" slot-scope="data">
                                    {{ data.item.model_year }} | {{ data.item.fuel }}
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-text-field type="number" autocomplete="nope" dense v-model="new_car.price" label="Preço" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-text-field type="number" autocomplete="nope" dense v-model="new_car.fipe" label="Preço FIPE" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-text-field type="number" autocomplete="nope" dense v-model="new_car.model_year" label="Ano modelo" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-text-field type="number" autocomplete="nope" dense v-model="new_car.km" label="Quilometragem" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-autocomplete autocomplete="nope" :no-data-text="noDataText" dense :items="$root.$refs.global.gearboxes" v-model="new_car.gearbox" item-text="name" item-value="id" label="Câmbio" outlined hide-details="auto"></v-autocomplete>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-autocomplete autocomplete="nope" :no-data-text="noDataText" dense :items="$root.$refs.global.fuels" v-model="new_car.fuel" item-text="name" item-value="id" label="Combustível" outlined hide-details="auto"></v-autocomplete>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-autocomplete autocomplete="nope" :no-data-text="noDataText" dense :items="states" v-model="new_car.sold" item-text="name" item-value="id" label="Vendido?" outlined hide-details="auto"></v-autocomplete>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-autocomplete autocomplete="nope" :no-data-text="noDataText" dense :items="stores" v-model="new_car.store" item-text="name" item-value="_id" label="Loja" outlined hide-details="auto"></v-autocomplete>
                        </v-col>

                        <v-col cols="12">
                            <div class="switch-container">
                                <v-switch dense v-model="new_car.featured" inset label="Destaque" style="margin-top: 4px;"></v-switch>
                                <v-switch class="ml-6" dense v-model="new_car.admin_only" inset label="Invisível" style="margin-top: 4px;"></v-switch>
                                <v-switch class="ml-6" dense v-model="new_car.landing_page_offer" inset label="Oferta de Landing Page" style="margin-top: 4px;"></v-switch>
                            </div>
                        </v-col>


                        <v-col cols="12" sm="6">
                            <v-text-field autocomplete="nope" dense v-model="new_car.description" label="Descrição" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-file-input accept="image/*" v-model="file" @change="uploadImage" label="Upload de foto" outlined dense :messages="fileInputMessage" :loading="buttonLoading" hide-details="auto"></v-file-input>
                        </v-col>

                        <v-col cols="12">
                            <div class="pictures-container">
                                <v-badge color="red" class="remove-image-badge" icon="mdi-close-thick" overlap v-for="(pic, index) in new_car.pictures" :key="index" @click.native="new_car.pictures.splice(index, 1)">
                                    <v-img class="image-thumb" contain :src="pic.thumb"></v-img>
                                </v-badge>
                            </div>
                        </v-col>

                        <v-col cols="12">
                            <v-btn class="default-button" color="#123cd7" block @click.native="createOrUpdateCar" :loading="buttonLoading">Salvar carro</v-btn>
                        </v-col>

                    </v-row>
                </v-card-text>
            </v-card>
        </v-bottom-sheet>

        <v-dialog v-model="dialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar carro
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar o carro <b>{{ new_car.name }}</b>? Essa operação é irreversível.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog = false; deleteCar()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "CarAddView",

    data() {
        return {
            cars: [],
            stores: [],
            filter: '',
            modalNewCar: false,
            new_car: { pictures: [] },
            noDataText: "Nenhuma opção",
            buttonLoading: false,
            fileInputMessage: '',
            dialog: false,
            file: null,
            states: [
                { id: true, name: "Sim" },
                { id: false, name: "Não" }
            ],
            brand_models: [],
            model_types_by_year: { years: [] },
            headers: [
                { text: "Foto", value: "pictures" },
                { text: "Modelo", value: "model", filterable: true },
                { text: "Ano modelo", value: "model_year", filterable: true },
                { text: "Km", value: "km", filterable: true },
                { text: "Loja", value: "store", filterable: true },
                { text: "Preço", value: "price", filterable: true },
                { text: "Data de cadastro", value: "created" },
                { text: "Ações", value: "actions" },
            ],
        };
    },


    async mounted() {
        await this.getStores();
        await this.getCars({ limit: 1000, filter: {} });
    },

    methods: {

        async getStores() {
            const resp = await Api.getStores();
            if (resp && resp.message) this.stores = resp.message;
        },

        async getCars(data) {
            const resp = await Api.getCars(data);
            if (resp && resp.message && !resp.message.error) this.cars = resp.message;
        },

        async createOrUpdateCar() {
            if (!this.new_car.model) return emitToastr('error', 'Modelo do carro obrigatório.');
            if (!this.new_car.brand) return emitToastr('error', 'Marca do carro obrigatória.');
            if (!this.new_car.price) return emitToastr('error', 'Preço obrigatório.');
            if (!this.new_car.model_year) return emitToastr('error', 'Ano modelo obrigatório.');
            if (!this.new_car.store) return emitToastr('error', 'Loja obrigatória.');

            this.new_car.model = this.new_car.model.trim();
            this.new_car.brand = this.new_car.brand.trim();
            this.buttonLoading = true;

            const resp = await Api.createOrUpdateCar(this.new_car);
            if (resp && resp.message) {
                this.limpaFormulario();
                this.modalNewCar = false;
                this.buttonLoading = false;
                this.getCars({ limit: 1000, filter: {} });
                return emitToastr('success', 'Carro cadastrado com sucesso.');
            }
        },

        limpaFormulario() {
            this.new_car = { pictures: [] };
        },

        async uploadImage(data) {
            if (data) {
                this.buttonLoading = true;
                this.fileInputMessage = "Comprimindo e salvando a imagem...";
                const resp = await Api.uploadImage(data);

                this.buttonLoading = false;

                if (resp && resp.message) {
                    if (!this.new_car.pictures) this.new_car.pictures = [];

                    if (this.new_car.pictures.length < 10) {
                        this.new_car.pictures.push(resp.message);
                    }
                    else {
                        emitToastr('error', 'Cada veículo pode ter no máximo 10 fotos cadastradas');
                    }
                }
                this.fileInputMessage = "";
                this.file = null;
            }
        },


        async deleteCar() {
            this.new_car.filed = true;

            const resp = await Api.createOrUpdateCar(this.new_car);
            if (resp && !resp.error) {
                this.limpaFormulario();
                this.modalNewCar = false;
                this.buttonLoading = false;
                this.getCars();
                return emitToastr('success', 'Carro apagado com sucesso.');
            }
        },

        async changeBrand(data) {
            if (data) {
                this.new_car.brand = data.brand;
                this.new_car.fipe_brand_id = data.id;

                const resp = await Api.getBrandModels(data.id);
                if (resp && !resp.error && resp.message) {
                    this.brand_models = resp.message;
                }
                else {
                    if (resp.error) {
                        emitToastr("error", resp.message);
                    }
                }
            }
        },

        async changeModel(data) {
            if (data) {
                this.new_car.model = data.model;
                this.new_car.fipe_model_id = data.fipe_code;

                const resp = await Api.getModelTypesByYear(data.fipe_code);
                if (resp && !resp.error && resp.message) {
                    this.model_types_by_year = resp.message;
                }
                else {
                    if (resp.error) {
                        emitToastr("error", resp.message);
                    }
                }
            }
        },

        async changeModelYear(data) {
            if (data) {
                this.new_car.model = data.model;
                this.new_car.fipe_model_id = data.fipe_code;

                const resp = await Api.getModelPrice(data.fipe_code);
                if (resp && !resp.error && resp.message) {
                    this.brand_models = resp.message;
                }
                else {
                    if (resp.error) {
                        emitToastr("error", resp.message);
                    }
                }
            }
        },

        changeYearModel(data) {
            if (data) {
                this.new_car.fipe_year_id = data.year_id;
                this.new_car.fuel = data.fuel;
                this.new_car.fipe = data.price;
                this.new_car.model_year = data.model_year;
            }
        }
    },
};
</script>
  
<style scoped>
.find-bar {
    display: flex;
    flex-direction: row;
    max-width: 400px;
}

.new-car-button {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.actions-container {
    display: flex;
    flex-direction: row;

}

.bottom-modal-container {
    padding-top: 20px;
}

.pictures-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.image-thumb {
    max-width: 60px;
    max-height: 60px;
    margin: 5px;
    margin-left: 15px;
}

.remove-image-badge {
    cursor: pointer;
}

.switch-container {
    display: flex;
}

@media only screen and (max-width: 768px) {}
</style>
  