import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import StoreRegister from "../views/StoreRegister.vue";
import StoreRegisters from "../views/StoreRegisters.vue";
import Dashboard from "../views/Dashboard.vue";
import Cars from "../views/Cars.vue";
import LPCarsOffers from "../views/LPCarsOffers.vue";
import Profile from "../views/Profile.vue";
import StoreAdd from "../views/StoreAdd.vue";
import CarAdd from "../views/CarAdd.vue";
import CarAddByStore from "../views/CarAddByStore.vue";
import UserAdd from "../views/UserAdd.vue";
import Denounces from "../views/Denounces.vue";
import Audit from "../views/Audit.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
    meta: {
      layout: "fullscreen",
    },
  },
  {
    path: "/cadastro-lojista",
    name: "store-register",
    component: StoreRegister,
    meta: {
      layout: "fullscreen",
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/carros",
    name: "Carros",
    component: Cars,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/grandes-ofertas",
    name: "Grandes Ofertas",
    component: LPCarsOffers,
    meta: {
      layout: "menu"
    },
  },
  {
    path: "/meu-perfil",
    name: "Perfil",
    component: Profile,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/lojas",
    name: "Lojas",
    component: StoreAdd,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/solicitacoes-lojistas",
    name: "Solicitações de Lojistas",
    component: StoreRegisters,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/denuncias",
    name: "Denúncias",
    component: Denounces,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/auditoria",
    name: "Auditoria",
    component: Audit,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/cadastrar-carro",
    name: "CarAdd",
    component: CarAdd,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/cadastrar-carro-lojista",
    name: "CarAddStore",
    component: CarAddByStore,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/usuarios",
    name: "UserAdd",
    component: UserAdd,
    meta: {
      layout: "menu",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ["/", "/password-recovery", "/cadastro-lojista", "/cadastro-lojista/", "/grandes-ofertas", "/grandes-ofertas/"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("token");

  if (authRequired && !loggedIn) {
    next("/");
  } else {
    next();
  }
});

export default router;
